import { Injectable } from '@angular/core';
import mixpanel, { Mixpanel } from 'mixpanel-browser';
import { environment } from '../../../environments/environment';
import { User, UserRole } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  mixpanel: Mixpanel;
  constructor() {
    this.init();
  }

  init(): void {
    let config = {};
    if (!environment.production) {
      config = { debug: true, api_host: 'https://api-eu.mixpanel.com' };
    }
    this.mixpanel = mixpanel.init(environment.mixpanelToken, config, 'CrateBase');
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user._id) {
      this.mixpanel.identify(user._id);
    }
  }

  track(id: string, action: any = {}): void {
    this.mixpanel.track(id, action);
  }

  identify(id: string) {
    this.mixpanel.identify(id);
  }

  registerAndCreateUserProfile(user: User) {
    if (!user.company) return;

    this.mixpanel.identify(user._id);
    this.mixpanel.register({
      Email: user.email,
      Name: user.fullName,
      'Company name': user.company.name,
    });
    this.mixpanel.people.set({
      $email: user.email,
      $name: user.fullName,
      'Company name': user.company.name,
    });
  }

  register(props: any) {
    this.mixpanel.register(props);
  }

  registerOnce(props: any) {
    this.mixpanel.register_once(props);
  }

  peopleSet(props: any) {
    this.mixpanel.people.set(props);
  }
}
