<div class="form-group" (click)="picker.open()">
  <input
    type="text"
    [placeholder]="placeholder"
    readonly
    [value]="startInput.value + (endInput.value ? ' - ' : '') + endInput.value"
  />

  <mat-date-range-input [rangePicker]="picker" [min]="min" [max]="effectiveMaxDate">
    <input matStartDate [(ngModel)]="start" (ngModelChange)="dateChanged()" #startInput />
    <input matEndDate [(ngModel)]="end" (ngModelChange)="dateChanged()" #endInput />
  </mat-date-range-input>
  <i class="icon suffix clear-icon" *ngIf="start || end" (click)="$event.stopPropagation(); clear()">clear</i>
  <i class="icon suffix" (click)="picker.open()">date_range</i>
  <mat-date-range-picker [dateClass]="dateClass" #picker></mat-date-range-picker>
</div>
